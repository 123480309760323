<template>
  <div ref="elementRef" v-style:bg="backgroundColor" class="md:flex" @click="handlePromotionClick">
    <div class="relative flex shrink-0" :class="mediaSize[imageWidth]">
      <div
        v-if="hasCaption"
        v-style:c="captionColor || subtitleColor"
        class="absolute right-0 mr-6"
        :class="captionPosition === 'bottom' ? 'bottom-0 mb-3' : 'top-0 mt-3'"
        style="max-width: 9.375rem"
      >
        {{ captionText }}
      </div>
      <div v-if="responsiveMediaList.length > 0" class="grid cols-2 w-full md:flex">
        <template v-for="(mediaObject, index) in responsiveMediaList">
          <base-picture
            v-if="mediaObject.images"
            :key="`picture-${index}`"
            v-bind="mediaObject.images"
            class="w-full"
            :class="getMediaClasses(index)"
            fit="cover"
            :lazy
          />
          <vf-video
            v-else-if="mediaObject.video"
            :key="`video-${index}`"
            v-bind="mediaObject.video"
            ref="videoRefs"
            :autoplay="!lazy"
            class="w-full"
            :class="getMediaClasses(index)"
            fit="cover"
            loop
            muted
          />
        </template>
      </div>
      <template v-else-if="responsiveMedia">
        <base-picture
          v-if="responsiveMedia.images"
          v-bind="responsiveMedia.images"
          class="w-full"
          fit="cover"
          :lazy
        />
        <vf-video
          v-else-if="responsiveMedia.video"
          v-bind="responsiveMedia.video"
          ref="videoRefs"
          :autoplay="!lazy"
          class="w-full"
          fit="cover"
          loop
          muted
        />
      </template>

      <base-link
        v-if="mediaLink"
        aria-hidden="true"
        class="absolute-0"
        tabindex="-1"
        :target="mediaLink.targetAttribute"
        :to="mediaLink.url"
      />
    </div>
    <div class="w-full f-col" :class="contentAlignment">
      <div v-if="alignmentLarge.startsWith('3')" class="grid cols-2 <md:hidden">
        <template v-for="(mediaObject, index) in responsiveMediaList.slice(-2)">
          <base-picture
            v-if="mediaObject.images"
            :key="`picture-${index}`"
            v-bind="mediaObject.images"
            class="w-full"
            fit="cover"
            :lazy
          />
          <vf-video
            v-else-if="mediaObject.video"
            :key="`video-${index}`"
            v-bind="mediaObject.video"
            ref="videoRefs"
            :autoplay="!lazy"
            class="w-full"
            fit="cover"
            loop
            muted
          />
        </template>
      </div>
      <div
        class="w-full f-col px-4 py-6 space-y-2 lg:px-6 lg:space-y-4"
        :class="[
          textAlignment,
          {
            'order-first': alignmentLarge === '3-top-left' || alignmentLarge === '3-top-right',
          }]"
      >
        <div class="space-y-2 lg:space-y-4" :class="overlayWidth ? contentSize[overlayWidth] : ''">
          <div v-if="eyebrowText" v-style:c="eyebrowTextColor || subtitleColor" class="uppercase">
            {{ eyebrowText }}
          </div>
          <base-picture
            v-if="images"
            v-bind="images"
            class="max-h-38"
            fit="contain"
            :props-img="{ class: 'object-left' }"
          />
          <base-picture
            v-else-if="eyebrowLogo"
            :alt="eyebrowLogo.alt"
            class="max-h-38"
            fit="contain"
            :props-img="{ class: 'object-left' }"
            :src="eyebrowLogo.url"
          />
          <p v-if="overline" v-style:c="overlineColor" :class="overlineStyle" data-test-id="cms-banner-overline">
            {{ overline }}
          </p>
          <h2
            v-if="title"
            v-style:c="titleColor"
            :class="[titleStyle, { 'sr-only': hideTitle }]"
            data-test-id="cms-banner-title"
          >
            <base-link v-if="mediaLink" :target="mediaLink.targetAttribute" :to="mediaLink.url">
              {{ title }}
            </base-link>
            <template v-else>
              {{ title }}
            </template>
          </h2>
          <p v-if="subtitle" v-style:c="subtitleColor" :class="subtitleStyle" data-test-id="cms-banner-text">
            {{ subtitle }}
          </p>
          <cms-rich-text v-if="richText" v-style:c="richTextColor || subtitleColor" :content="{ richText }" data-test-id="cms-banner-body" />
        </div>
        <div
          v-if="linkType !== 'No-CTA' && targets.length"
          class="flex-col gap-4 i-flex md:flex-row md:wrap lg:gap-6 not-first:pt-4 <md:w-full!"
          :class="[
            overlayWidth ? contentSize[overlayWidth] : '',
            ctaClass,
          ]"
          :style="`--cols: repeat(${targets.length},minmax(0,1fr))`"
        >
          <cms-shared-button
            v-for="(target, i) in targets"
            :key="i"
            v-bind="{ ...target, ...getTextLinkStyles(content) }"
            :size
          />
        </div>
        <div :class="overlayWidth ? contentSize[overlayWidth] : ''">
          <cms-rich-text
            v-if="richTextBelowTargets"
            v-style:c="richTextBelowTargetsColor || subtitleColor"
            :content="{ richText: richTextBelowTargets }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey, type SectionProvider } from '#content/components/cms/section/context'
import { getAlignment } from '#content/utils'
import type {
  BannerSplitAlignmentLarge,
  BannerSplitAlignmentSmall,
  BannerSplitContent,
  BannerSplitMediaWidth,
  VerticalAlignment
} from '#types/components/cms/banner-split'
import type { TextAlignment } from '#types/components/cms/text-alignment'

const { content } = defineProps<{
  content: BannerSplitContent
}>()

const {
  alignmentLarge,
  alignmentSmall,
  backgroundColor,
  captionColor,
  captionPosition,
  captionText,
  equalTargetSize,
  eyebrowLogo,
  eyebrowLogoResponsive,
  eyebrowText,
  eyebrowTextColor,
  hideTitle,
  imageWidth = '60',
  linkType,
  media,
  mediaList = [],
  mediaTarget,
  name,
  overlayWidth,
  overline,
  overlineColor,
  overlineStyle,
  promotionTracking,
  richText,
  richTextBelowTargets,
  richTextBelowTargetsColor,
  richTextColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  textAlignmentLarge,
  textAlignmentSmall,
  title,
  titleColor,
  titleStyle,
  verticalAlignment
} = content

const { size } = useAppConfig().components.cms.sharedButton
const { getMedia } = useCms()
const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, name)

const { lazy } = inject(SectionContextKey, {} as SectionProvider)
const videoRefs = ref<HTMLVideoElement[]>([])
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)
const responsiveMediaList = mediaList.map(getMedia)
const { images } = getMedia(eyebrowLogoResponsive)

const smallImageLayout = getAlignment(alignmentSmall) || 0
const imageLayout = getAlignment(alignmentLarge) || 0

const contentAlignmentLarge: Record<BannerSplitAlignmentLarge, string> = {
  'left-left': 'md:text-left md:order-first',
  'left-right': 'md:text-left md:order-last',
  'center-left': 'md:text-center md:order-first',
  'center-right': 'md:text-center md:order-last',
  'right-left': 'md:text-right md:order-first',
  'right-right': 'md:text-right md:order-last',
  '1-left': 'md:order-first',
  '1-right': 'md:order-last',
  '2-left': 'md:order-first',
  '2-right': 'md:order-last',
  '3-bottom-left': 'md:order-first',
  '3-bottom-right': 'md:order-last',
  '3-top-left': 'md:order-first',
  '3-top-right': 'md:order-last',
}

const contentAlignmentSmall: Partial<Record<BannerSplitAlignmentSmall, string>> = {
  left: '<md:text-left',
  center: '<md:text-center',
  right: '<md:text-right'
}

const ctaAlignmentLarge: Partial<Record<BannerSplitAlignmentLarge | TextAlignment, string>> = {
  'left-left': 'md:justify-start',
  'left-right': 'md:justify-start',
  'center-left': 'md:justify-center',
  'center-right': 'md:justify-center',
  'right-left': 'md:justify-end',
  'right-right': 'md:justify-end',
  'left': 'md:justify-start',
  'center': 'md:justify-center',
  'right': 'md:justify-end'
}

const ctaAlignmentSmall: Partial<Record<TextAlignment, string>> = {
  left: '<md:items-start',
  center: '<md:items-center',
  right: '<md:items-end'
}

const textAlignmentSmallClasses: Record<TextAlignment, string> = {
  left: '<md:items-start <md:text-left',
  center: '<md:items-center <md:text-center',
  right: '<md:items-end <md:text-right'
}

const textAlignmentLargeClasses: Record<TextAlignment, string> = {
  left: 'md:items-start md:text-left',
  center: 'md:items-center md:text-center',
  right: 'md:items-end md:text-right'
}

const verticalAlignmentClasses: Record<VerticalAlignment, string> = {
  top: 'md:justify-start',
  middle: 'md:justify-center',
  bottom: 'md:justify-end'
}

const hasCaption = captionText && captionPosition !== 'off'

const mediaSize: Record<BannerSplitMediaWidth, string> = {
  60: 'md:w-3/5',
  50: 'md:w-1/2',
  40: 'md:w-2/5'
}

const getMediaClasses = (index: number) => ({
  '<md:col-span-2': index === 0 && [3, 1, 0].includes(smallImageLayout),
  '<md:hidden': smallImageLayout && index > smallImageLayout - 1,
  'md:hidden': (imageLayout && imageLayout === 3 && index !== 0) || (smallImageLayout && index > smallImageLayout - 1)
})

const equalTargetSizeClass = [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:cols-$cols <md:auto-rows-fr',
  getValueForBreakpoint('md', equalTargetSize) && '~md:grid ~md:cols-$cols ~md:auto-rows-fr ~md:items-stretch',
  getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid lg:cols-$cols lg:auto-rows-fr lg:items-stretch'
].filter(Boolean).join(' ')

const contentAlignment = [
  contentAlignmentLarge[alignmentLarge],
  contentAlignmentSmall[alignmentSmall],
  verticalAlignmentClasses[verticalAlignment]
]

const textAlignment = [
  textAlignmentSmallClasses[textAlignmentSmall],
  textAlignmentLargeClasses[textAlignmentLarge]
]

const ctaClass = [
  ctaAlignmentLarge[alignmentLarge],
  ctaAlignmentSmall[alignmentSmall],
  ctaAlignmentLarge[textAlignmentLarge],
  ctaAlignmentSmall[textAlignmentSmall],
  equalTargetSizeClass
]

const contentSize = {
  xs: 'w-10/12 md:w-1/3',
  sm: 'w-10/12 md:w-1/2',
  md: 'w-10/12 md:w-2/3',
  lg: 'w-full md:w-11/12'
}

onMounted(() => videoRefs.value.forEach(({ play }) => play()))
</script>
