<template>
  <define-segmentation v-slot="{ invisible }">
    <component
      :is="`lazy-cms-${component}`"
      v-if="component"
      :key="segmentContent.id"
      :class="{ invisible, 'h-full children:h-full': fullHeight }"
      :content="segmentContent"
      :data-test-id="`cms-${component}-${segmentContent.id}`"
    />
  </define-segmentation>

  <client-only>
    <div
      v-style:display="getVisibilityStyles(segmentContent.hideOnBreakpoint)"
      :class="{ container, 'h-full': fullHeight }"
    >
      <segmentation-template />
    </div>
    <template v-if="!enableCSROnlyForSegmentation" #fallback>
      <div
        v-style:display="getVisibilityStyles(segmentContent.hideOnBreakpoint)"
        class="skeleton"
        :class="{ container }"
      >
        <segmentation-template :invisible="true" />
      </div>
    </template>
  </client-only>
</template>

<script lang="ts" setup>
import { SectionContextKey, type SectionProvider } from '#content/components/cms/section/context'
import type { Segmentation } from '#types/components/cms/segmentation'
import mappings from '#content/mappings'

const props = defineProps<{
  content: Segmentation
}>()

const { fullHeight } = inject(SectionContextKey, {} as SectionProvider)

const auth = useAuthStore()
const { isLoyaltyMember, isWranxProfile, isIpaProfile, isWranxOrIpaProfile, isAthleteProfile }
  = storeToRefs(useUserStore())
const cart = useCartStore()
const query = useRouteQuery('p13n_testcontext', '{}')
const { enableCSROnlyForSegmentation } = useFeatureFlags()

const [DefineSegmentation, SegmentationTemplate] = createReusableTemplate()

const segmentsMap = computed(() => ({
  'loggedout': !auth.loggedIn,
  'loggedin': auth.loggedIn,
  'loyalty-loggedin': isLoyaltyMember.value,
  'non-loyalty-loggedin': auth.loggedIn && isLoyaltyMember.value,
  'employee-loggedin': auth.loggedIn && auth.isEmployee,
  'non-employee-loggedin': auth.loggedIn && !auth.isEmployee,
  'cart-empty': !cart.totalItems,
  'cart-not-empty': cart.totalItems,
  'ipa-wranx-loggedin': auth.loggedIn && isWranxOrIpaProfile.value,
  'non-ipa-wranx-loggedin': auth.loggedIn && !isWranxOrIpaProfile.value,
  'wranx-loggedin': auth.loggedIn && isWranxProfile.value,
  'non-wranx-loggedin': auth.loggedIn && !isWranxProfile.value,
  'ipa-loggedin': auth.loggedIn && isIpaProfile.value,
  'non-ipa-loggedin': auth.loggedIn && !isIpaProfile.value,
  'athletes-loggedin': auth.loggedIn && isAthleteProfile.value,
  'non-athletes-loggedin': auth.loggedIn && !isAthleteProfile.value,
}))

// override can be set in the CMS to preview specific segments
const segmentOverride = computed(() => JSON.parse(query.value)?.segment)

const segmentContent = computed(() => {
  const segment = segmentOverride.value
    ? props.content.segments?.find(({ id }) => id === segmentOverride.value)
    : props.content.segments?.find(({ id }) => segmentsMap.value[id])
  return { ...segment?.targets?.[0] || props.content.baselines?.[0], sectionId: props.content.id }
})

const component = computed(() => resolveContentComponent(segmentContent.value, mappings)?.component)

/**
 * If the parent component is manually (!) set as full width we should take this as source for applying container class
 * Otherwise we should look into direct segment component configuration
 */
const container = computed(() => {
  if (typeof props.content !== 'undefined' && props.content.isFullWidth !== undefined)
    return !props.content.isFullWidth

  return !segmentContent.value?.isFullWidth
})

const emptySections = useState<Record<string, boolean>>('emptySections', () => ({}))

watch(component, (component) => {
  emptySections.value[props.content.id] = !component
}, { immediate: true })
</script>
